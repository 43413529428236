import React from "react"
import PropTypes from "prop-types"
import { customProperties } from "../../lib/exports"

const Logo = props => {
  const widthValue = isNaN(props.width) ? props.width : props.width + "px"
  const heightValue = isNaN(props.height) ? props.height : props.height + "px"
  const ariaId = `${props.title}_title`
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 4096.000000 4096.000000"
      preserveAspectRatio="xMidYMid meet"
      style={{
        minWidth: widthValue,
        width: widthValue,
        height: heightValue,
      }}
      aria-hidden="true"
      className={"icon " + props.className}
      aria-labelledby={ariaId}
    >
      <title>{props.title}</title>
      <g
        transform="translate(0.000000,4096.000000) scale(0.100000,-0.100000)"
        fill={props.fill}
        stroke="none"
      >
        <path
          d="M217 27153 c-4 -7479 -7 -16629 -7 -20335 l0 -6736 92 -6 c51 -3
9202 -8 20335 -12 l20241 -6 6 74 c3 40 8 9193 12 20339 l6 20266 -13584 6
c-7470 4 -16623 7 -20339 7 l-6756 0 -6 -13597z m29179 9362 c13 -13 17 -684
34 -5507 11 -3021 22 -6899 24 -8618 3 -1719 8 -3853 12 -4742 l7 -1618 -21 0
-22 0 -2 503 -3 502 -5 -605 c-3 -333 -3 -612 0 -620 3 -8 6 33 8 93 2 99 4
107 22 107 19 0 20 -7 20 -144 0 -140 -1 -144 -22 -150 -13 -3 -25 -2 -29 2
-3 4 -1281 2547 -2839 5652 -3278 6531 -5427 10814 -6675 13300 -485 965 -878
1757 -873 1758 4 2 8 10 8 17 0 18 299 21 4900 50 2142 14 4019 27 4170 28
1020 9 1271 7 1286 -8z m-12437 -21755 c2858 -5695 5194 -10356 5189 -10358
-4 -2 -8 -10 -8 -17 0 -18 -245 -20 -5667 -56 -4256 -28 -4664 -29 -4678 -15
-15 14 -19 915 -50 9848 -42 11906 -40 10638 -15 10638 20 0 20 -7 22 -502 l3
-503 5 605 c3 333 3 612 0 620 -3 8 -6 -33 -8 -92 -2 -100 -4 -108 -22 -108
-19 0 -20 7 -20 144 0 129 2 145 18 149 9 3 21 4 25 3 4 -1 2347 -4661 5206
-10356z"
        />
        <path
          d="M29023 36483 c65 -2 168 -2 230 0 62 1 9 3 -118 3 -126 0 -177 -2
-112 -3z"
        />
        <path
          d="M27668 36473 c138 -2 366 -2 505 0 138 1 25 2 -253 2 -278 0 -391 -1
-252 -2z"
        />
        <path
          d="M26198 36463 c138 -2 366 -2 505 0 138 1 25 2 -253 2 -278 0 -391 -1
-252 -2z"
        />
        <path
          d="M24713 36453 c142 -2 372 -2 510 0 139 1 23 2 -258 2 -280 0 -394 -1
-252 -2z"
        />
        <path
          d="M23238 36443 c138 -2 366 -2 505 0 138 1 25 2 -253 2 -278 0 -391 -1
-252 -2z"
        />
        <path
          d="M21763 36433 c142 -2 372 -2 510 0 139 1 23 2 -258 2 -280 0 -394 -1
-252 -2z"
        />
        <path
          d="M20273 36423 c141 -2 373 -2 515 0 141 1 25 2 -258 2 -283 0 -399 -1
-257 -2z"
        />
        <path
          d="M19080 36413 c0 -5 73 -7 162 -5 305 4 333 8 86 10 -137 1 -248 -1
-248 -5z"
        />
        <path
          d="M19670 35230 c138 -275 252 -500 255 -500 3 0 -107 225 -245 500
-138 275 -252 500 -255 500 -3 0 107 -225 245 -500z"
        />
        <path
          d="M20955 32670 c135 -269 247 -490 250 -490 3 0 -105 221 -240 490
-135 270 -247 490 -250 490 -3 0 105 -220 240 -490z"
        />
        <path
          d="M22200 30190 c116 -231 212 -420 215 -420 3 0 -89 189 -205 420 -116
231 -212 420 -215 420 -3 0 89 -189 205 -420z"
        />
        <path
          d="M23485 27630 c113 -225 207 -410 210 -410 3 0 -87 185 -200 410 -113
226 -207 410 -210 410 -3 0 87 -184 200 -410z"
        />
        <path
          d="M29395 27060 c0 -201 1 -283 2 -182 2 100 2 264 0 365 -1 100 -2 18
-2 -183z"
        />
        <path
          d="M29405 24790 c0 -564 1 -794 2 -512 2 281 2 743 0 1025 -1 281 -2 51
-2 -513z"
        />
        <path
          d="M24810 24990 c138 -275 252 -500 255 -500 3 0 -107 225 -245 500
-138 275 -252 500 -255 500 -3 0 107 -225 245 -500z"
        />
        <path
          d="M26095 22430 c135 -269 247 -490 250 -490 3 0 -105 221 -240 490
-135 270 -247 490 -250 490 -3 0 105 -220 240 -490z"
        />
        <path
          d="M29405 21815 c0 -533 1 -750 2 -482 2 269 2 705 0 970 -1 265 -2 46
-2 -488z"
        />
        <path
          d="M27380 19870 c138 -275 252 -500 255 -500 3 0 -107 225 -245 500
-138 275 -252 500 -255 500 -3 0 107 -225 245 -500z"
        />
        <path
          d="M29415 18935 c0 -561 1 -789 2 -507 2 283 2 742 0 1020 -1 279 -2 48
-2 -513z"
        />
        <path
          d="M28665 17310 c135 -269 247 -490 250 -490 3 0 -105 221 -240 490
-135 270 -247 490 -250 490 -3 0 105 -220 240 -490z"
        />
        <path
          d="M12505 23520 c135 -269 247 -490 250 -490 3 0 -105 221 -240 490
-135 270 -247 490 -250 490 -3 0 105 -220 240 -490z"
        />
        <path
          d="M11765 21895 c0 -561 1 -789 2 -507 2 283 2 742 0 1020 -1 279 -2 48
-2 -513z"
        />
        <path
          d="M13790 20960 c138 -275 252 -500 255 -500 3 0 -107 225 -245 500
-138 275 -252 500 -255 500 -3 0 107 -225 245 -500z"
        />
        <path
          d="M11775 18965 c0 -561 1 -789 2 -507 2 283 2 742 0 1020 -1 279 -2 48
-2 -513z"
        />
        <path
          d="M15075 18400 c135 -269 247 -490 250 -490 3 0 -105 221 -240 490
-135 270 -247 490 -250 490 -3 0 105 -220 240 -490z"
        />
        <path
          d="M11785 16040 c0 -564 1 -794 2 -512 2 281 2 743 0 1025 -1 281 -2 51
-2 -513z"
        />
        <path
          d="M16360 15840 c138 -275 252 -500 255 -500 3 0 -107 225 -245 500
-138 275 -252 500 -255 500 -3 0 107 -225 245 -500z"
        />
        <path
          d="M11795 13115 c0 -561 1 -789 2 -507 2 283 2 742 0 1020 -1 279 -2 48
-2 -513z"
        />
        <path
          d="M17645 13280 c135 -269 247 -490 250 -490 3 0 -105 221 -240 490
-135 270 -247 490 -250 490 -3 0 105 -220 240 -490z"
        />
        <path
          d="M18930 10720 c138 -275 252 -500 255 -500 3 0 -107 225 -245 500
-138 275 -252 500 -255 500 -3 0 107 -225 245 -500z"
        />
        <path
          d="M11805 10185 c0 -561 1 -789 2 -507 2 283 2 742 0 1020 -1 279 -2 48
-2 -513z"
        />
        <path
          d="M20215 8160 c135 -269 247 -490 250 -490 3 0 -105 221 -240 490 -135
270 -247 490 -250 490 -3 0 105 -220 240 -490z"
        />
        <path
          d="M11815 7265 c0 -561 1 -789 2 -507 2 283 2 742 0 1020 -1 279 -2 48
-2 -513z"
        />
        <path
          d="M21500 5600 c138 -275 252 -500 255 -500 3 0 -107 225 -245 500 -138
275 -252 500 -255 500 -3 0 107 -225 245 -500z"
        />
        <path
          d="M11822 4848 l-2 -508 243 2 242 3 -237 2 -238 3 -3 503 -2 502 -3
-507z"
        />
        <path
          d="M21850 4420 l-245 -6 248 -2 c159 -1 247 2 247 8 0 6 -1 9 -2 8 -2
-1 -113 -5 -248 -8z"
        />
        <path
          d="M20393 4403 c141 -2 373 -2 515 0 141 1 25 2 -258 2 -283 0 -399 -1
-257 -2z"
        />
        <path
          d="M18918 4393 c145 -2 379 -2 520 0 141 1 23 2 -263 2 -286 0 -402 -1
-257 -2z"
        />
        <path
          d="M17443 4383 c141 -2 373 -2 515 0 141 1 25 2 -258 2 -283 0 -399 -1
-257 -2z"
        />
        <path
          d="M15963 4373 c141 -2 373 -2 515 0 141 1 25 2 -258 2 -283 0 -399 -1
-257 -2z"
        />
        <path
          d="M14483 4363 c141 -2 373 -2 515 0 141 1 25 2 -258 2 -283 0 -399 -1
-257 -2z"
        />
        <path
          d="M13013 4353 c141 -2 373 -2 515 0 141 1 25 2 -258 2 -283 0 -399 -1
-257 -2z"
        />
      </g>
    </svg>
  )
}

Logo.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.oneOf(["auto"]), PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.oneOf(["auto"]), PropTypes.number]),
  className: PropTypes.string,
  title: PropTypes.string,
}

Logo.defaultProps = {
  height: customProperties["--logo-header-size"],
  width: "auto",
  fill: customProperties["--color-transparent-bg"],
  title: "Logo",
  className: "",
}

export default Logo

import React from "react"
import PropTypes from "prop-types"
import { customProperties } from "../../lib/exports"

const Signature = props => {
  const widthValue = isNaN(props.width) ? props.width : props.width + "px"
  const heightValue = isNaN(props.height) ? props.height : props.height + "px"
  const ariaId = `${props.title}_title`
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200.000000 180.000000"
      preserveAspectRatio="xMidYMid meet"
      style={{
        minWidth: widthValue,
        width: widthValue,
        height: heightValue,
      }}
      aria-hidden="true"
      className={"icon " + props.className}
      aria-labelledby={ariaId}
    >
      <title>{props.title}</title>
      <g
        transform="translate(0.000000,181.000000) scale(0.100000,-0.100000)"
        fill={props.fill}
        stroke="none"
      >
        <path
          d="M7641 1780 c-151 -69 -399 -462 -517 -818 l-48 -143 -84 -80 c-130
-123 -254 -200 -347 -214 -33 -6 -44 -3 -59 14 -51 56 -24 275 71 599 l28 93
35 -5 c140 -21 273 -33 318 -27 73 8 62 27 -15 23 -59 -3 -301 33 -315 47 -3
3 11 53 33 110 41 107 43 131 11 131 -27 0 -34 -11 -78 -132 l-39 -108 -97 0
c-80 0 -96 3 -92 14 3 8 -2 16 -13 19 -40 12 -213 36 -301 43 -69 5 -92 10
-88 19 44 114 86 250 82 262 -4 9 -16 13 -33 11 -25 -3 -30 -11 -59 -98 -17
-52 -41 -116 -52 -142 l-21 -48 -58 0 c-89 -1 -183 -17 -287 -50 -94 -30 -117
-48 -90 -71 12 -9 28 -6 82 19 98 45 332 91 332 65 0 -13 -102 -197 -143 -257
-153 -227 -321 -366 -443 -366 -58 0 -104 27 -104 61 0 26 -18 59 -32 59 -5 0
-37 -25 -70 -55 -87 -80 -132 -70 -88 19 24 51 81 106 109 106 10 0 25 -10 34
-22 12 -18 18 -20 28 -11 10 10 10 18 1 38 -14 30 -55 48 -93 40 -39 -9 -104
-68 -128 -118 -60 -125 16 -185 128 -102 43 31 55 35 62 24 5 -8 9 -17 9 -21
0 -13 48 -40 87 -48 45 -11 133 5 188 32 136 69 291 247 423 483 37 65 36 61
-1 -65 -149 -501 -190 -652 -227 -838 -33 -166 -37 -267 -10 -267 11 0 17 26
27 117 21 203 103 517 247 948 l81 245 60 -1 c80 -1 337 -36 328 -44 -5 -4
-34 -12 -65 -19 -52 -11 -58 -15 -58 -38 0 -21 4 -24 23 -20 122 29 221 47
264 47 l52 0 -15 -42 c-8 -24 -35 -119 -59 -213 -75 -292 -71 -447 13 -482 92
-38 238 28 433 199 l66 57 -5 -74 c-5 -81 4 -105 42 -105 28 1 51 21 126 113
64 79 86 97 114 97 13 0 16 -13 16 -64 0 -54 4 -70 24 -93 21 -24 31 -27 77
-26 74 1 190 42 337 118 l122 64 0 -35 c0 -119 126 -136 396 -53 93 29 280
107 294 124 25 30 -15 24 -112 -18 -165 -72 -274 -107 -373 -119 -83 -11 -95
-10 -119 5 -21 14 -26 25 -26 57 0 34 3 40 20 40 32 0 78 27 85 50 20 61 -75
68 -140 10 -66 -59 -368 -199 -462 -215 -36 -6 -43 -4 -53 15 -6 12 -9 34 -5
50 8 37 -11 106 -31 114 -9 3 -31 6 -49 6 -41 0 -75 -27 -156 -126 -33 -41
-64 -74 -70 -74 -14 0 -11 63 7 141 15 67 20 74 132 202 406 460 568 720 508
812 -21 31 -63 33 -125 5z m97 -22 c32 -32 -7 -129 -117 -291 -83 -122 -293
-384 -385 -482 -65 -69 -66 -69 -50 -30 9 22 22 56 29 75 29 81 128 277 200
399 150 251 274 378 323 329z m312 -908 c0 -12 -38 -32 -52 -28 -14 5 19 38
38 38 8 0 14 -4 14 -10z"
        />
        <path
          d="M9562 1778 c-214 -105 -551 -744 -578 -1094 -6 -78 -6 -82 16 -94 37
-20 66 -3 128 73 128 155 154 163 162 50 6 -92 20 -108 93 -107 75 1 170 35
325 113 122 62 132 66 132 46 0 -41 20 -81 49 -98 66 -39 278 -3 485 82 55 23
101 39 104 37 2 -3 -1 -28 -7 -56 -15 -69 -14 -209 2 -240 31 -62 100 -81 209
-59 111 22 345 158 516 299 20 16 37 28 39 27 1 -2 -7 -31 -18 -66 -18 -61
-18 -101 1 -101 12 0 20 10 89 115 33 50 84 114 114 143 95 89 126 59 67 -66
-34 -72 -37 -97 -14 -106 12 -5 37 13 83 57 104 100 136 108 160 39 46 -131
137 -151 355 -78 103 35 291 120 301 137 16 25 -30 15 -116 -25 -125 -59 -243
-102 -319 -116 -76 -14 -92 -5 -140 74 -33 54 -76 86 -114 86 -20 0 -73 -23
-102 -45 -21 -15 -21 -15 -16 35 12 122 -97 121 -205 -4 -38 -42 -40 -41 -16
9 12 26 14 38 6 46 -20 20 -58 2 -75 -34 -27 -56 -264 -236 -428 -325 -86 -46
-195 -82 -250 -82 -45 0 -68 23 -81 80 -11 48 3 181 27 252 14 42 20 48 68 68
259 105 616 491 619 668 3 170 -198 154 -374 -29 -110 -115 -251 -354 -329
-557 l-35 -93 -88 -39 c-164 -72 -334 -120 -429 -120 -48 0 -78 28 -78 73 0
31 3 34 42 43 79 17 90 94 14 94 -21 0 -48 -13 -82 -37 -71 -54 -325 -179
-415 -204 -102 -30 -109 -26 -109 58 0 90 -15 113 -76 113 -39 0 -49 -5 -92
-48 -26 -26 -66 -71 -90 -100 -24 -28 -48 -52 -54 -52 -7 0 -8 20 -4 60 13
110 31 152 102 231 289 320 503 609 549 738 19 56 19 115 -1 135 -22 22 -71
20 -122 -6z m106 -42 c8 -63 -59 -186 -208 -385 -150 -200 -360 -444 -360
-417 0 13 81 199 127 291 164 327 335 552 413 543 19 -2 26 -10 28 -32z m1517
-159 c54 -77 -38 -250 -240 -452 -49 -50 -121 -112 -159 -139 -74 -52 -196
-120 -203 -112 -17 17 111 287 201 426 38 57 89 128 114 157 107 127 243 183
287 120z m-1220 -737 c-18 -20 -45 -27 -45 -12 0 15 24 32 44 32 18 0 19 -1 1
-20z"
        />
        <path
          d="M2153 1626 c-23 -8 -62 -25 -86 -39 -140 -83 -354 -397 -442 -652
-16 -44 -33 -85 -39 -92 -16 -16 -186 -86 -297 -121 -53 -17 -130 -34 -173
-38 -71 -6 -78 -5 -101 16 -17 16 -25 34 -25 55 0 30 3 33 38 39 46 7 75 35
70 68 -2 19 -10 23 -44 26 -32 2 -48 -2 -71 -22 -60 -51 -360 -152 -485 -163
l-67 -6 -28 47 c-47 81 -65 97 -108 102 -33 4 -49 0 -92 -28 -29 -18 -71 -48
-94 -67 l-41 -34 36 72 c20 39 34 76 31 81 -9 14 -51 12 -63 -2 -14 -18 -72
-185 -72 -209 0 -43 30 -31 112 46 80 75 167 130 175 110 21 -56 63 -122 83
-132 14 -7 53 -13 87 -13 99 0 212 28 418 105 l60 22 1 -44 c2 -99 94 -126
282 -83 90 21 270 83 332 115 l26 13 -13 -71 c-18 -105 -16 -195 5 -237 70
-141 338 -59 678 206 l81 63 8 -67 c10 -84 10 -187 0 -230 -10 -45 9 -41 40 8
35 55 107 99 330 200 105 48 205 94 223 104 31 16 32 16 32 -4 0 -33 17 -57
47 -67 38 -13 89 2 108 31 14 22 21 23 83 18 81 -5 271 12 400 37 50 10 92 17
92 16 0 -1 -9 -28 -20 -60 -44 -129 -17 -145 83 -48 83 79 123 109 159 118 24
6 27 3 42 -43 25 -75 53 -96 136 -100 85 -5 179 15 353 74 155 54 214 81 199
96 -7 7 -53 -6 -144 -41 -156 -58 -312 -101 -373 -101 -47 0 -61 11 -93 76
-26 51 -41 63 -86 71 -43 7 -97 -19 -174 -85 l-55 -47 32 62 c38 77 40 103 7
103 -14 0 -31 -8 -38 -18 -20 -29 -203 -62 -420 -78 -129 -9 -146 -4 -150 44
-3 34 -6 37 -33 37 -27 0 -30 -4 -33 -33 -2 -18 2 -40 9 -48 9 -11 8 -18 -6
-34 -21 -24 -63 -26 -71 -5 -8 20 12 69 37 91 23 21 25 43 3 51 -9 3 -27 -6
-47 -25 -31 -30 -76 -53 -360 -182 -77 -35 -163 -78 -193 -96 l-54 -33 0 38
c0 21 -5 63 -11 94 -10 48 -9 64 5 101 9 24 26 52 36 61 11 10 20 24 20 32 0
26 -36 12 -75 -28 -91 -93 -327 -264 -455 -329 -72 -36 -183 -70 -230 -70 -30
0 -43 6 -60 27 -21 26 -22 36 -17 138 3 61 12 133 21 160 16 49 17 51 105 92
296 141 629 537 582 693 -6 20 -20 45 -30 54 -26 24 -86 29 -138 12z m120 -44
c29 -26 22 -99 -18 -178 -64 -128 -233 -315 -382 -421 -63 -45 -193 -117 -199
-110 -8 8 39 126 94 237 179 358 400 564 505 472z m-1203 -732 c0 -13 -48 -36
-56 -27 -4 4 -1 13 6 22 14 17 50 20 50 5z"
        />
      </g>
    </svg>
  )
}

Signature.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.oneOf(["auto"]), PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.oneOf(["auto"]), PropTypes.number]),
  className: PropTypes.string,
  title: PropTypes.string,
}

Signature.defaultProps = {
  height: customProperties["--signature-header-size"],
  width: "auto",
  fill: customProperties["--color-transparent-bg"],
  title: "Signature",
  className: "",
}

export default Signature
